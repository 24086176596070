// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anyoccasion-js": () => import("./../src/pages/anyoccasion.js" /* webpackChunkName: "component---src-pages-anyoccasion-js" */),
  "component---src-pages-birthday-js": () => import("./../src/pages/birthday.js" /* webpackChunkName: "component---src-pages-birthday-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cupcakes-js": () => import("./../src/pages/cupcakes.js" /* webpackChunkName: "component---src-pages-cupcakes-js" */),
  "component---src-pages-flavours-js": () => import("./../src/pages/flavours.js" /* webpackChunkName: "component---src-pages-flavours-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourphilosophy-js": () => import("./../src/pages/ourphilosophy.js" /* webpackChunkName: "component---src-pages-ourphilosophy-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

